<template>
    <footer class="site-footer">
        <div class="copyright">2019@miracle-All rights reserved.<br>Copyright by 米洛科技網頁設計</div>
    </footer>
</template>

<script>
export default {
  name: 'sitefooter'
}
</script>

<style scoped >
.site-footer{
    text-align: right;
    font-size: 12px;
    padding: 0 40px 20px;
}

</style>
