<template>
    <div class="layout">
        <sitenav></sitenav>
        <div class="layout__right">
            <siteheader></siteheader>
            <transition mode="out-in" name="slide-fade"><router-view/></transition>
            <sitefooter></sitefooter>
        </div>
    </div>
</template>



<script>
import siteheader from '@/components/siteheader.vue';
import sitenav from '@/components/sitenav.vue';
import sitefooter from '@/components/sitefooter.vue'
export default {
    components: {
        siteheader, sitenav, sitefooter
    },
    data() {
        return {
        }
    },
    created(){
        const vm = this;
        const apiPrama = vm.getParamsJson({ "s": this.$cookies.get('skey')});
        
        // this.$store.dispatch('initcheck', apiPrama);
        // 也可以用下面的方式，使用Promise的方式接收回傳訊息。(不過當然initcheck要return Promise)
        this.$store.dispatch('initcheck', apiPrama)
            .then((res) =>{
                if(!res.userData){
                    vm.$router.push('/login');
                }
            })
            .catch((err) => {
                // console.log("initcheck dispatch 失敗: ", err)
            });

    },
    methods: {
    },
}
</script>