<template>
    <div id="app" class="wrapper">    
        <router-view/>
    </div>
</template>



<script>

export default {

    data(){
        return {
            // lifetime: 30, //倒數登出秒數
        }
    },
    methods: {
        // getParamsJson(msg) {
        //     return window.btoa(window.encodeURIComponent(JSON.stringify(msg)));
        // },
        // setEndTime(msg) {
        //     const vm = this;
        //     console.log(msg,'setEndTime()', vm);

        //     let lifetime = 1440; //注意!! 單位是秒
        //     if(vm.$cookies.isKey("maxlifetime")){
        //         console.log('cookie有maxlifetime:', Number(vm.$cookies.get('maxlifetime')) );
        //         lifetime = Number(vm.$cookies.get('maxlifetime'));
        //     }

        //     const time = new Date();
        //     const nowTime = time.getTime();  //現在時間 (1970~)
        //     const endTime = nowTime + (lifetime*1000);  //預計登出時間 (1970~)
        //     console.log('預計登出時間 (1970~):', endTime);


        //     //最終將預計登出時間 (1970~) 存入cookie
        //     vm.$cookies.set('logoutTime', endTime);
        // }
    },
    created() {
        // console.log("App.vue");
        //在全域變數$bus上，用on的方式註冊一個 自訂義方法
        // this.$bus.$on('setEndTime', (msg) => {
        //     this.setEndTime(msg)
        // })

    },
    mounted() {
        // this.setEndTime('App.vue觸發');

    }
}
</script>




<style lang="scss">

</style>
