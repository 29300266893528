import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '@/views/Dashboard.vue' //這邊或下面 import 皆可
import Login from '@/views/login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: '/login'
  },{
    path: '/login',
    name: '登入(不知客戶)',
    component: Login
  },
  {
    path: '/login/:key',
    name: '登入',
    component: Login
  },
  {
    path: '/',
    component: Dashboard,
    children: [
      {
        path: '', //此為父path預設
        name: '首頁',
        component: () => import('@/views/index.vue'),
        meta: { requiresAuth: true },
      }
    ]
  },
  {
    path: '/info',
    name: '基本資料',
    component: Dashboard,
    children: [
      {
        path: 'account',
        name: '管理者帳密',
        component: () => import('@/views/info_account.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'summary', // path: 'summary'
        name: '服務年限總覽',
        component: () => import('@/views/info_summary.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'contract',
        name: '合約方案',
        component: () => import('@/views/info_contract.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'backstage',
        name: '網站後台相關資訊',
        component: () => import('@/views/info_backstage.vue'),
        meta: { requiresAuth: true }
      }
    ]
  },
  {
    path: '/news',
    name: '我的訊息',
    component: Dashboard,
    children: [
      {
        path: ':fnid',
        component: () => import('@/views/news.vue'),
        meta: { requiresAuth: true }
      }
    ]
  },
  {
    path: '/document',
    name: '案件製作',
    component: Dashboard,
    children: [
      {
        path: 'prebuild',
        name: '製前說明',
        component: () => import('@/views/document_prebuild.vue'),
        meta: { requiresAuth: true }
      },{
        path: 'demand',
        name: '需求單',
        component: () => import('@/views/document_demand.vue'),
        meta: { requiresAuth: true }
      },{
        path: 'discuss',
        name: '討論留言區',
        component: () => import('@/views/document_discuss.vue'),
        meta: { requiresAuth: true }
      },{
        path: 'proof',
        name: '校稿專區',
        component: () => import('@/views/document_proof.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'qa',
        name: '案件常見問題',
        component: () => import('@/views/document_qa.vue')
      }
    ]
  },
  {
    path: '/service',
    name: '我的客服',
    component: Dashboard,
    children: [
      {
        path: 'bulletin',
        name: '後台使用須知',
        component: () => import('@/views/service_bulletin.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'maintain',
        name: '後續維護',
        component: () => import('@/views/service_maintain.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'msgboard',
        name: '聯繫客服',
        component: () => import('@/views/service_msgboard.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'qa',
        name: '客服常見問題',
        component: () => import('@/views/service_qa.vue'),
        meta: { requiresAuth: true }
      },
    ]
  },
  {
    path: '/valueadded',
    name: '加值服務',
    component: Dashboard,
    children: [
      {
        path: 'list',
        name: '加費項目一覽表',
        component: () => import('@/views/valueadded_list.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'contact',
        name: '聯絡我們',
        component: () => import('@/views/valueadded_contact.vue'),
        meta: { requiresAuth: true }
      },
    ]
  },
  {
    path: '/qa',
    component: Dashboard,
    children: [
      {
        path: '',
        name: '常見問題',
        component: () => import('@/views/qa.vue'),
        meta: { requiresAuth: true }
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
