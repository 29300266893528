import Vue from 'vue'
import Vuex from 'vuex'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookies from 'vue-cookies'
import store from './store'

//全域都載入UI套件跟新後台樣式
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// import '@/assets/style.css'
import '@/assets/css/style_fix.scss'

import App from './App.vue'
// import './bus'

Vue.use(Vuex)
Vue.use(VueAxios, axios)
Vue.use(ElementUI)
Vue.use(VueCookies)
Vue.config.productionTip = false
// Vue.prototype.$APP = App


Vue.mixin({
    methods: {
        getParamsJson(obj) {
            return 'parame=' + window.btoa(window.encodeURIComponent(JSON.stringify(obj)));
        },
        setEndTime(msg) {
            const vm = this;
            let lifetime = 1440; //這裡單位是秒
            if(vm.$cookies.isKey("maxlifetime")){
                lifetime = Number(vm.$cookies.get('maxlifetime'));
            }
            const time = new Date();
            const nowTime = time.getTime();  //現在時間 (1970~)
            const endTime = nowTime + (lifetime*1000);  //預計登出時間 (1970~)
            //最終將預計登出時間 (1970~) 存入cookie
            vm.$cookies.set('logoutTime', endTime);
        }

    }
})

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')


//Vue Router 导航守卫功能
// router.beforeEach((to, from, next) => {
//     console.log("from:", from, "to:", to, "next:", next);
//     //檢查to的網址是否需要登入
//     if(to.meta.requiresAuth){
//         console.log(document.cookie);

//         const apiUrl = `${process.env.VUE_APP_APIHOST}/api/_init.php`;
//         // const apiParam = Vue.getParamsJson({'s': Vue.$cookies.get('skey')});
//         //因為不是寫在vue裡，所以不用this.。改成直接用axios.post()
//         axios.get(apiUrl).then((response) => {
//             console.log(response.data);
//             if(response.data.success && response.data.userData){
//                 next();
//             }else{
//                 console.log("init 檢查 false!!!!");
//                 next();
//                 // next({
//                 //     path: '/login'
//                 // });
//             }
//         })

//     }else{
//         next();
//     }

// })

// router.afterEach((to, from) => {
//   console.log("from:", from, "to:", to);
//   //檢查to的網址是否需要登入
//   if(to.meta.requiresAuth){
//     // const apiUrl = `${process.env.VUE_APP_APIHOST}/api/_init.php?key=${from.params.key}`;
//     const apiUrl = `${process.env.VUE_APP_APIHOST}/api/_init.php`;
//     //下面這行，因為不是寫在vue裡，所以不用this.。改成直接用axios.post()
//     axios.get(apiUrl).then((response) => {
//         console.log("_init回應 :", response);
//         if(response.data.success){
//           console.log("放行!!!!!!!!");
//         }else{
//           console.log("init檢查沒有登入，轉往登入頁");
//         }
//     })

//   }else{
//     next();
//   }
// })