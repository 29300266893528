<template>
    <nav class="layout__left site-nav">
        <el-menu
            router
            v-if="menus"
            :default-openeds="defaultOopeneds"
            @open="handleOpen"
            @close="handleClose">
            <el-menu-item index="/" class="logo">
                <img src="@/assets/logo.svg" alt="米管家">
            </el-menu-item>
            <el-submenu index="/info" v-if="menus.info && menus.info.canView">
                <template slot="title">
                    <i class="el-icon-star-off"></i>
                    <span>基本資料</span>
                </template>
                <el-menu-item index="/info/account">管理者帳密</el-menu-item>
                <el-menu-item index="/info/summary">服務年限總覽</el-menu-item>
                <el-menu-item index="/info/contract">合約方案</el-menu-item>
                <el-menu-item index="/info/backstage" v-if="menus.info.backstage">網站後台相關資訊</el-menu-item>
            </el-submenu>
            <el-submenu index="/news" v-if="menus.news && menus.news.canView">
                <template slot="title">
                    <i class="el-icon-message"></i>
                    <span>我的訊息</span>
                </template>
                <el-menu-item 
                    v-for="submenu in menus.news.submenus" 
                    :index="`/news/${submenu.fn_id}`"
                    :key="submenu.fn_id">
                    {{submenu.fn_name}}
                </el-menu-item>
            </el-submenu>
            <el-submenu index="/document" v-if="menus.document && menus.document.canView">
                <template slot="title">
                    <i class="el-icon-tickets"></i>
                    <span>案件製作</span>
                </template>
                <el-menu-item index="/document/prebuild">製前說明</el-menu-item>
                <el-menu-item index="/document/demand">需求單</el-menu-item>
                <el-menu-item index="/document/discuss">討論留言區</el-menu-item>
                <el-menu-item index="/document/proof">校稿專區<span class="navBadge" v-if="menus.document.proof.hasNew">新</span></el-menu-item>
                <el-menu-item index="/document/qa" v-if="false">
                    常見問題<span class="navBadge" v-if="menus.document.qa.hasNew">NEW</span>
                </el-menu-item>
            </el-submenu>
            <el-submenu index="/service" v-if="menus.service && menus.service.canView">
                <template slot="title">
                    <i class="el-icon-headset"></i>
                    <span>我的客服</span>
                </template>
                <el-menu-item index="/service/bulletin">後台使用須知</el-menu-item>
                <el-menu-item index="#link" :key="'https://www.webtech.com.tw/newtech/'">
                    <a href="https://www.webtech.com.tw/newtech/" target="_blank" title="另開視窗">後台操作教學<svg class="ic_open" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0V0z" fill="none"/><path class="ic_open__main" d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"/></svg></a>
                </el-menu-item>
                <el-menu-item index="/service/maintain">後續維護</el-menu-item>
                <el-menu-item index="/service/msgboard">聯繫客服</el-menu-item>
                <el-menu-item index="/service/qa" v-if="false">
                    常見問題<span class="navBadge" v-if="menus.service.qa.hasNew">NEW</span>
                </el-menu-item>
            </el-submenu>
            <el-submenu index="/valueadded" v-if="menus.valueadded && menus.valueadded.canView">
                <template slot="title">
                    <i class="el-icon-star-off"></i>
                    <span>加值服務</span>
                </template>
                <el-menu-item index="/valueadded/list">加費項目一覽表</el-menu-item>
                <el-menu-item index="/valueadded/contact">聯絡我們</el-menu-item>
            </el-submenu>
            <el-menu-item index="/qa" v-if="menus.qa && menus.qa.canView" class="menu_l1">
                <i class="el-icon-chat-square"></i>常見問題<span class="navBadge" v-if="menus.qa.qa.hasNew">NEW</span>
            </el-menu-item>
        </el-menu>
    </nav>
</template>

<script>
//將vuex中的getters載入
import { mapGetters } from 'vuex';

export default {
    props: {
        // userData:{
        //     type: Object
        // }
    },
    data() {
        return {
            
            // menus: {}
        }
    },
    computed: {
        // 屬性寫在Vuex內時，需要使用computed去取得：
        // menus(){
        //     return this.$store.state.menus;
        // },
        // 但為了管理方便，通通都寫在vuex內，這裡只要載入vuex中getters內的menus這個參數  *注意開頭需先import {mapGetters}
        ...mapGetters(['menus', 'userData', 'caseing']),
        defaultOopeneds(){
            if(this.caseing){
                return ['/document']
            }else{
                return ['/info','/news','/document','/service','/valueadded']
            }
        },

    },
    created() {
        const vm = this;
        //抓取選單內容menu.php (寫在Vuex內，故這裡使用dispatch調用Vuex的action)
        const apiParam_o = {"nowpage": vm.$route.matched[0].path, "s": vm.$cookies.get('skey')};
        const apiParam = vm.getParamsJson(apiParam_o);
        this.$store.dispatch('getMenu', apiParam);

    },
    methods: {
        handleOpen(key, keyPath) {
            // console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            // console.log(key, keyPath);
        }
    }
}
</script>

<style lang="scss" scoped>
.ic_open{
    margin: 0 0 0 3px;
    width: 1.1em;
    height: 1.1em;
    vertical-align: middle;
    &__main{
        transition: all .2s;
        fill: #999;
    }
}
.site-nav {
    position: sticky;
    top: 0;
    height: 100vh;
    background: #fff;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
        width: 4px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background: #ddd; 
        border-radius: 2px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #aaa; 
    }
}

.site-nav .logo {
    height: auto;
    &:focus, &:hover{
        background-color: transparent;
    }
    img{
        position: relative;
        display: block;
        margin: 0 auto;
        padding: 20px 0;
        width: 70%;
        height: auto;
    }
}


::v-deep .el-submenu .el-menu-item{
    height: 32px;
    line-height: 32px;
    a{
        position: absolute;
        width: 100%;
        display: block;
        left: 0;
        top: 0;
        padding: 0 45px 0 40px;
        box-sizing: border-box;
    }
}
::v-deep .el-submenu__title, ::v-deep .menu_l1{
    height: 40px;
    line-height: 40px;
    font-weight: 600;
    background: #ecf1f1;
}
::v-deep .el-menu-item:not(.logo).is-active{
    background: linear-gradient(45deg, #1e5799 0%,#2989d8 59%,#7db9e8 100%);
    color: #fff;
}


body.el-popup-parent--hidden{  //dialog出現且不能捲動時
    .wrapper{
        filter: blur(3px);
    }
}

/*選單名稱徽章*/
.navBadge{
    display: inline-block;
    padding: 0.25em .5em;
    font-size: 12px;
    text-align: center;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0;
    background: #f56c6c;
    color: #fff;
    border-radius: 0.8em;
    transform: scale(0.75);
}
</style>
