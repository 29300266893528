import Vue from 'vue';
import Vuex from 'vuex';
import router from '../router/index';
import axios from 'axios';
Vue.use(Vuex);
export default new Vuex.Store({
    //state類似vue中的data，用來放資料
    state: {
        menus: {},
        customerItem: {},
        userData: {},
        maxlifetime: '',
        caseing: '',
        indexinfo: {}
    },

    //action放非同步事件，vue組件必須使用dispatch調用這裡的事件
    actions: {
        initcheck(context, apiParam) {
            // const url = `${process.env.VUE_APP_APIHOST}/api/_init.php`;
            // axios.post(url, apiParam).then((response) => {
            //     console.log('■■■ initcheck ■■■ ', response.data);
                // if(response.data.success){
                //     context.commit('INIT', response.data);
                // }else{
                //     // vm.$message.error('請重新登入');
                //     router.push('/login');
                // }
            // })
            // 也可以用下面的方式做，return Promise資訊給vue組件，再由vue處理
            return new Promise((resolve, reject) => {
                const url = `${process.env.VUE_APP_APIHOST}/api/_init.php`;
                axios.post(url, apiParam).then((response) => {
                    if(response.data.success){
                        context.commit('INIT', response.data);
                        resolve(response.data);  //成功回傳
                    }else{
                        reject(response.data);  //失敗回傳
                        router.push('/login');
                    }
                })
            });
        },
        getMenu(context, apiParam) {
            //收到vue組件傳來的參數，送往API查詢
            const url = `${process.env.VUE_APP_APIHOST}/api/menu.php`;
            axios.post(url, apiParam).then((response) => {
                // console.log("menu.php回應:", response.data);
                //用commit將查詢結果送到mutations，mutations的MENU負責實際異動資料
                context.commit('MENUS', response.data);
            })
        },
        getIndexInfo(context, apiParam){
            const url = `${process.env.VUE_APP_APIHOST}/api/index_info.php`;
            axios.post(url, apiParam).then((response) => {
                // console.log("--- getIndex 回應:", response.data);
                context.commit('INDEXINFO', response.data);
            })
        }
    },

    //mutations 放 實際操作資料 或 同步動作，建議名稱寫全大寫常數
    mutations: {
        INIT(state, payload) {
            state.customerItem = payload.customerItem;
            state.userData = payload.userData;
            state.maxlifetime = payload.maxlifetime;
        }, 
        MENUS(state, payload) {
            state.menus = payload.menus;
        },
        INDEXINFO(state, payload) {
            state.caseing = payload.caseing;
            state.indexinfo.OwnExpense = payload.OwnExpense;
            state.indexinfo.OwnExpense_self = payload.OwnExpense_self;
            state.indexinfo.webprocess = payload.webprocess;
        },
    },
    //getters等同於vue的computed，Vue呈現資料時，必須用computed去取得Vuex的值，而，所以直接寫在這，Vue只要寫入即可
    getters: {  
        //使用箭頭函式寫法  原寫法 menus(state){ return state.menus}
        menus: state => state.menus,
        customerItem: state => state.customerItem,
        userData: state => state.userData,
        caseing: state => state.caseing,
        indexinfo: state => state.indexinfo,
    }

});
