<template>
    <header class="site-header">
        <div class="site-header__comName">{{customerItem.cus_name}}</div>
        <i class="sep" v-if="customerItem.cus_weburl"></i>
        <a class="site-header__website" v-if="customerItem.cus_weburl" :href="customerItem.cus_weburl" target="_blank" title="開啟前台網站">前往網站<svg class="ic_open" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0V0z" fill="none"/><path class="ic_open__main" d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"/></svg></a>
        <i class="sep" v-if="customerItem.cus_managerurl"></i>
        <a class="site-header__webManager" v-if="customerItem.cus_managerurl" :href="customerItem.cus_managerurl" target="_blank" title="開啟網站後台">後台<svg class="ic_open" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0V0z" fill="none"/><path class="ic_open__main" d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"/></svg></a>
        <i class="sep"></i>
        <div class="site-header__account" :title="'目前使用此'+userData.username+'帳號登入中'"><span><i class="el-icon-user"></i></span> {{userData.cus_account}}</div>
        <div class="site-header__tray">
            <div 
                class="site-header__countdown" 
                v-if="logoutCountDown != 0" 
                :class="{'warning_slow': logoutCountDown<180 && logoutCountDown>59, 'warning_fast': logoutCountDown<60}">
                倒數<span class="timeout">{{logout_mm}}:{{logout_ss}}</span><span class="logout_btn">登出</span>
            </div>
            <div class="site-header__countdown" v-else>
                已登出，重新登入?
            </div>
            <button class="site-header__logout" @click="logout('click')">立即登出</button>
        </div>
        <el-dialog
            title="已自動登出，請重新登入"
            :visible.sync="reLoginShow"
            width="400px"
            :show-close="false"
            :append-to-body="true"
            :close-on-press-escape="false"
            :close-on-click-modal="false"
            :before-close="handleClose"
            @keyup.enter="login">
            <el-form ref="form" :model="user" label-width="70px" class="reLoginForm" >
                <el-form-item label="帳號">
                    {{user.username}}
                </el-form-item>
                <el-form-item label="密碼">
                    <el-input v-model="user.userpwd" placeholder="請輸入密碼" show-password @focus="checknumImgVisible ? '' : initcheck()"></el-input>
                </el-form-item>
                <el-form-item label="驗證碼" >
                    <el-input v-model="user.checknum" placeholder="請輸入右側數字" maxlength="5" @keyup.enter.native="login">
                        <i class="el-icon-refresh-left" slot="suffix" @click="chgTs" title="更新驗證碼圖片"></i>
                        <span slot="append">
                            <el-tooltip class="item" effect="dark" content="點擊可更新圖片" placement="bottom">
                                <img id="rand-img" v-show="checknumImgVisible" :src="checknumImgUrl" @click="chgTs" />
                            </el-tooltip>
                        </span>
                    </el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="$router.push(`/login/${$cookies.get('key')}`);">離開</el-button>
                <el-button type="primary" @click="login">重新登入</el-button>
            </span>
        </el-dialog>
    </header>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    data(){
        return {
            logoutCountDown: 1440,  //距離登出時間的秒數
            logoutTimer: null,  //登出計時器
            reLoginShow: false,
            checknumImgVisible: false, //驗證碼圖片是否顯示
            user: {  //準備用來重新登入用的資訊
                username: '',
                userpwd: '',
                checknum: '',
                s: '',
            },
            ts: '',  //時間戳記
        }
    },
    computed: {
        checknumImgUrl(){
            return `${process.env.VUE_APP_APIHOST}/webtech_lib/code/imgcheck.php?s=${this.user.s}&type=login&w=100&tt=${this.ts}`;
        },
        logout_ss(){
            let ss = parseInt(this.logoutCountDown % 60); //剩餘秒
            return ss < 10 ? '0'+ss : ss;
        },
        logout_mm(){
            let mm = parseInt(this.logoutCountDown / 60 % 60) //剩餘分
            return mm < 10 ? '0'+mm : mm;
        },
        ...mapGetters(['customerItem', 'userData']),
    },
    watch:{
        customerItem(val){
            document.getElementById("pagetitle").innerText = "米管家 - " + val.cus_name;
        }
    },
    mounted(){
        this.countdown();
    },
    beforeDestroy () {
        clearInterval(this.logoutTimer);
    },
    methods: {
        countdown(){
            const vm = this;
            const warningTime = 60;
            if(vm.logoutTimer){
                clearInterval(vm.logoutTimer);
            }

            vm.logoutTimer = setInterval(function(){
                const time = new Date();
                const nowTime = time.getTime();  //現在時間 (1970~)
                
                let endTime = Number(this.$cookies.get('logoutTime'));  //取出預計登出時間 (1970~) 毫秒
                let offsetTime = parseInt( (endTime - nowTime)/1000 );  //算出距離登出還有幾秒
                vm.logoutCountDown = offsetTime;
                if(offsetTime == warningTime){
                    vm.$message({
                        showClose: true,
                        message: '請留意右上角自動登出時間',
                        duration: (warningTime - 10)*1000,
                        type: 'warning'
                    });
                }else if(offsetTime<2){
                    // console.log('----- times up ----');
                    clearInterval(vm.logoutTimer);
                    vm.logoutCountDown = 0;
                    vm.logout();
                    return
                }
            },1000);
        },
        logout(n) {          
            // console.log("------ logout(n)，n:", n);
            const vm = this;
            clearInterval(vm.logoutTimer);
            const apiUrl = `${process.env.VUE_APP_APIHOST}/api/logout.php`;
            const apiParam = vm.getParamsJson({'s': vm.$cookies.get('skey')});
            this.$http.post(apiUrl, apiParam).then((response) => {
                // console.log("------ logout 回應:", response.data);
                if(response.data.success){
                    if(response.data.key){
                        vm.$cookies.set('key', response.data.key);
                    }
                    vm.$cookies.remove('skey');
                    vm.$cookies.remove('logoutTime');
                    if(n != 'click'){
                        // this.$alert('因閒置過久，系統已自動將您登出，請重新登入', '需要重新登入', {
                        //     confirmButtonText: '知道了'
                        // });
                        //停在原畫面
                        vm.showLogin();
                    }else{
                        vm.$router.push(`/login/${response.data.key}`);
                    }
                }else{
                    vm.$message.error('Oops! 出了點差錯，請通知米洛客服 @@');
                }
            })

        },
        showLogin() {
            const vm = this;
            vm.user.username = vm.userData.cus_account;
            vm.reLoginShow = true;
            // vm.initcheck();
            // console.log(vm.$cookies.get('key'));
        },
        handleClose(){
            console.log("handleClose()!!!");
        },
        chgTs(){
            let d = new Date();
            this.ts = d.getTime();
        },
        initcheck(){
            // console.log("initcheck()!!!!!!!!!!!");
            const vm = this;
            const apiUrl = `${process.env.VUE_APP_APIHOST}/api/_init.php?key=${vm.$cookies.get('key')}`;
            vm.$http.get(apiUrl).then((response) => {
                // console.log("initcheck回應 :", response.data);
                if(response.data.success){
                    vm.user.s = response.data.skey;
                    vm.$cookies.set('maxlifetime', response.data.maxlifetime);
                    vm.checknumImgVisible = true;
                }else{
                    // console.log("initcheck錯誤");
                }
            })

        },
        login() {
            const vm = this;
            if(!vm.user.userpwd || !vm.user.checknum){
                vm.$message.error('請輸入密碼與驗證碼');
                return
            }
            let api = `${process.env.VUE_APP_APIHOST}/api/_login.php`;
            // console.log('即將送往後端的vm.user:', vm.user);
            let apiParam = vm.getParamsJson(vm.user);
            this.$http.post(api, apiParam).then((response) => {
                console.log('_login回應:', response.data);
                if(response.data.success){
                    vm.$cookies.set('skey', vm.user.s);
                    vm.setEndTime();
                    vm.reLoginShow = false;
                    vm.checknumImgVisible = false;
                    vm.countdown();
                    vm.user.userpwd = '';
                    vm.user.checknum = '';
                }else{
                    if(response.data.message == 'needVerifyCode'){
                        vm.$message('請填寫驗證碼');
                    }else if(response.data.message == '_login_pwd_error_'){
                        vm.$message.error('密碼錯誤');
                    }else if(response.data.message == 'ChecknumError'){
                        vm.chgTs();
                        vm.$message.error('驗證碼錯誤，請重新輸入驗證碼');
                    }else{
                        vm.$message.error('不明錯誤，請通知米洛客服');
                    }
                }
                
            })
        },
    }
}
</script>

<style scoped lang="scss">
.ic_open{
    margin: 0 0 0 2px;
    width: 1em;
    height: 1em;
    vertical-align: middle;
    &__main{
        transition: all .2s;
        fill: #888;
    }
}

.site-header{
    position: fixed;
    top: 0;
    right: 0;
    z-index: 10;
    display: flex;
    justify-content: flex-end;
    // align-items: center;
    width: calc(100% - 200px);
    line-height: 34px;
    background: rgba($color: #f0f2f5, $alpha: .85);
    cursor: default;

    &__comName{
        padding: 0 1em;
    }
    &__website, &__webManager{
        padding: 0 1em;
        transition: all .2s;
        &:hover{
            background: #2c82be;
            color: #fff;
            .ic_open__main{
                fill: #fff;
            }
        }
    }
    &__account{
        position: relative;
        padding: 0 1em;
        span{
            display: inline-block;
            width: 1.6em;
            height: 1.6em;
            line-height: 1.6em;
            color: #fff;
            background: #bbb;
            border-radius: 50%;
            vertical-align: middle;
            text-align: center;
            font-size: .8em;
        }
    }
    
    .sep{
        margin: 0 -1px;
        align-self: center;
        width: 1px;
        height: 1em;
        background: rgba($color: #000, $alpha: .1);
    }
}
.site-header__tray{
    position: relative;
    overflow: hidden;
    .site-header__countdown{
        padding: 0 20px;
        color: #fff;
        background-color: #2ab7b5;
        &.warning_slow{
            animation: willlogout 3s infinite linear;
        }
        &.warning_fast{
            animation: willlogout 1s infinite linear;
        }
    }
    .timeout{
        margin: 0 4px;
        font-size: 18px;
        font-weight: 700;
    }

    .site-header__logout{
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #2ab7b5;
        color: white;
        font-size: 1em;
        text-align: center;
        font-family: inherit;
        font-weight: 700;
        outline: none;
        border: 0;
        transform: translateX(-100%);
        opacity: 0;
        transition: all .25s;
        cursor: pointer;
    }
    &:hover{
        .site-header__logout{
            background-color: #e84185;
            transform: translateX(0%);
            opacity: 1;

        }
    }
}
@keyframes willlogout{
    0%{background-color: #2c82be}
    50%{background-color: #ff0b65}
    100%{background-color: #2c82be}
}


.reLoginForm::v-deep{
    .el-input__suffix{
        font-size: 18px;
        line-height: 40px;
    }
    .el-input-group__append{
        padding: 0;
        border: 0;
        height: 40px;
        width: 112px;
        background: #e6e6e6;
    }
}
#rand-img{
    vertical-align: top;
    height: 40px;
}
</style>
