<template>
    <div class="loginbox" v-loading.fullscreen.lock="fullscreenLoading">
        <div class="left">
            <div class="logo" :style="logoStyle"></div>
            <div class="name">{{companyname}}</div>
            <div class="ex"><span class="line-word">建議使用</span><span>Chrome瀏覽器／解析度1440*800以上裝置</span></div>
        </div>
        <div class="right" v-loading="loginProcess" element-loading-text="登入中">
            <template v-if="gotLost">
                <div class="right__title"><i class="el-icon-error"></i> {{gotLostMsg}}</div>
            </template>
            <template v-else>
                <div class="right__title" >Login<small>登入</small></div>
                <div class="form-box">
                    <form name="eForm1" id="eForm1" @submit.prevent="login">
                        <div class="username">
                            <div class="form-group">
                                <input v-model.trim="user.username" :disabled="loginProcess" required >
                                <label class="form-label" for="cusplat_name">帳號</label>
                            </div>
                            <div class="errormsg"></div>
                        </div>
                        <div class="userpwd">
                            <div class="form-group">
                                <input v-model="user.userpwd" :disabled="loginProcess" required type="password">
                                <label class="form-label" for="userpwd">密碼</label>
                            </div>
                            <div class="errormsg"></div>
                        </div>
                        <div class="checknum">
                            <div class="form-group">
                                <input v-model.trim="user.checknum" :disabled="loginProcess" required autocomplete="off" maxlength="5" type="text" title="請填入右方數字">
                                <label class="form-label" for="checknum">驗證碼</label>
                                <el-tooltip class="item" effect="dark" content="點擊可更換圖片" placement="bottom">
                                    <img id="rand-img" :src="checknumImgUrl" @click="chgTs" />
                                </el-tooltip>
                            </div>
                            <span class="errormsg"></span>
                        </div>
                        <button class="loginbtn" type="submit" :disabled="!user.userpwd || !user.username || !user.checknum">登　入</button>
                    </form>
                </div>
            </template>
        </div>
    </div>
</template>



<script>
export default {
    data(){
        return{
            fullscreenLoading: true,
            loginProcess: false,
            companyname: '',
            customerLogo: '',
            key: '',
            gotLost: false,
            gotLostMsg: '您開啟的網址不正確',
            user:{
                username: '',
                userpwd: '',
                checknum: '',
                s: '',
                // recaptcha_response: '',
            },
            ts: '',  //現在時間戳記，用來更新驗證碼圖片
        }
    },
    computed: {
        checknumImgUrl(){
            return `${process.env.VUE_APP_APIHOST}/webtech_lib/code/imgcheck.php?s=${this.user.s}&type=login&w=100&tt=${this.ts}`;
        },
        logoStyle(){
            const vm = this;
            if(vm.customerLogo){
                return {backgroundImage: `url(${vm.customerLogo})`}
            }else{
                // 在vue中，動態路徑圖檔是一個資源，要用require才能被webpack編譯
                return {backgroundImage: `url(${require("@/assets/logo.svg")})`}
            }
        }
    },
    created(){
        this.pagestart();
    },
    watch: {
        '$route' (to, from) {
            this.pagestart();
        },
        companyname(val){
            document.getElementById("pagetitle").innerText = "米管家 - "+val;
        }
    },
    methods: {
        pagestart(){
            const vm = this;
            vm.gotLost = false;
            vm.key = '';

            //check cookie，如果有就直接檢查狀態是否為登入中，若是則轉往首頁
            const skey = this.$cookies.get('skey');
            if(skey){
                //有cookies
                const apiUrl = `${process.env.VUE_APP_APIHOST}/api/_init.php`;
                const apiParam = vm.getParamsJson({'s': skey});
                this.$http.post(apiUrl, apiParam).then((response) => {
                    if(response.data.success && response.data.userData){
                        vm.$router.push('/');
                        return
                    }
                })
            }

            //check url參數 確認用戶要登入的公司
            if(vm.$route.params.key && vm.$route.params.key != null){
                //網址有公司參數，存入cookie，日後還能帶出最後一間公司
                vm.key = vm.$route.params.key;
                vm.$cookies.set('key', vm.key);
                vm.initcheck();
            }else{

                //網址沒有公司參數，去檢查cookie裡有沒有
                if(vm.$cookies.get('key')){
                    vm.$router.replace(`/login/${vm.$cookies.get('key')}`);
                    // vm.initcheck();
                }else{
                    vm.gotLost = true;
                    vm.gotLostMsg = "您開啟的網址不正確";
                    vm.fullscreenLoading = false;
                }
            }

        },
        initcheck(){
            const vm = this;
            let apiUrl = '';
            if(vm.key){
                apiUrl = `${process.env.VUE_APP_APIHOST}/api/_init.php?key=${vm.key}`;
            }else{
                apiUrl = `${process.env.VUE_APP_APIHOST}/api/_init.php`;
            }
            
            vm.$http.get(apiUrl).then((response) => {
                if(response.data.success){
                    vm.companyname = response.data.customerItem.cus_name; //將公司名稱顯示在畫面上
                    vm.customerLogo = response.data.customerItem.weblogo;
                    vm.user.s = response.data.skey;  //將skey存在data內，供取驗證碼圖、登入成功後存cookie用
                    vm.$cookies.set('maxlifetime', response.data.maxlifetime);  //將session存活時間 存在cookie用來做計時
                    vm.fullscreenLoading = false;
                }else{
                    if(response.data.message == '缺少客戶代碼'){
                        vm.gotLost = true;
                        vm.gotLostMsg = "您開啟的網址不正確";
                    }else if(response.data.message == "CAN-NOT-USE-SYSTEM"){
                        vm.gotLost = true;
                        vm.gotLostMsg = "尚未開通系統";
                    }else{
                        vm.gotLost = true;
                        vm.gotLostMsg = response.data.message;
                    }
                    vm.fullscreenLoading = false;
                }
            })
        },
        chgTs(){
            let d = new Date();
            this.ts = d.getTime();
        },
        login() {
            let api = `${process.env.VUE_APP_APIHOST}/api/_login.php`;
            const vm = this;
            if(!vm.user.username || !vm.user.userpwd || !vm.user.checknum){
                vm.$message('請先輸入帳號密碼後再登入');
                return
            }
            vm.loginProcess = true;
            let apiParam = vm.getParamsJson(vm.user);
            this.$http.post(api, apiParam).then((response) => {
                if(response.data.success){
                    vm.$cookies.set('skey', vm.user.s);
                    vm.setEndTime();
                    vm.$router.push('/');
                }else{
                    if(response.data.message == '_login_account_pwd_error_'){
                        vm.$message.error('無此帳號，請確認帳號名稱');
                    }else if(response.data.message == '_login_pwd_error_'){
                        vm.$message.error('密碼錯誤');
                    }else if(response.data.message == 'ChecknumError'){
                        vm.chgTs();
                        vm.$message.error('驗證碼錯誤，請重新輸入驗證碼');
                    }else{
                        vm.$message.error('不明錯誤，請通知米洛客服');
                    }

                }
                
                vm.loginProcess = false;
            })
        },
    },
}

</script>

<style lang="scss" scoped>
#checknum{
    width: 50px;
}

.loginbox{
    position: absolute;
    left: 50%;
    top: 49%;
    transform: translate(-50%,-50%);
    width: 90%;
    max-width: 1200px;
    display: flex;
    align-items: center;
    z-index:1; 
    animation: fadein .6s .3s 1 both;
}
@keyframes fadein{
    0%{ opacity: 0; }
    100%{ opacity: 1; }
}
.system{
    padding: 0;
    margin: 0;
    margin-top: 10px;
    list-style: none;
    z-index: 1;
    position: absolute;
    bottom: -30px;
    left: 0;
    right: 0;
    text-align: center;
}

.left{
    position: relative;
    flex: 2 1 55%;
    text-align: center;
    box-sizing: border-box;
    margin: 0 80px 0 0;
}
.right{
    position: relative;
    flex: 1 1 45%;
    padding: 35px 50px;
    border-radius: 14px;
    background-color:#fff;
    box-sizing: border-box;
}


.logo{
    width: 320px;
    height: 320px;
    margin: 0 auto;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    overflow: hidden;
    text-align: center;
}
.logo img{
    max-width: 80%;
}


.name{
    max-width: 380px;
    margin: 60px auto;
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 1.4;
    color: #595855;
    overflow: hidden;
}

.ex{
    position: relative;
    max-width: 380px;
    margin: 0 auto;
    font-size: 13px;
    color: #888;
}
.line-word{
    position: relative;
    display: flex;
    align-items: center;
    margin: 15px 0 4px;
    &::before, &::after{
        content: "";
        height: 1px;
        flex: 1 1 auto;
        background: #ccc;
    }
    &::before{
        margin: 0 1.5em 0 0;
    }
    &::after{
        margin: 0 0 0 1.5em;
    }
}





.right__title{
    font-size: 38px;
    color: #222;
    font-weight: bolder;
    margin-bottom: 90px;
}
.right__title small{
    font-weight: normal;
    font-size: 26px;
    margin-left: 10px;
}

.errormsg{
    font-size: 14px;
    color: #f57c7c;
    margin-top: 6px;
}
.loginbtn{
    position: relative;
    display: block;
    margin: 60px auto 20px;
    padding: .6em 1em;
    width: 100%;
    max-width: 160px;
    outline: none;
    border: 0;
    border-radius: 30px;
    color: #fff;
    font-size: 18px;
    font-family: inherit;
    cursor: pointer;
    background: #e84185;
    transition: all .15s ease-in-out;
    
    &:not([disabled]):hover:after{
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: transparent;
        border-radius: 30px;
        box-shadow: rgba(232,65,133,.8) 0 0 10px;
        transform: scale(1.3);
        transform-origin: center;
        animation: bigfadeout 1s ease 0s infinite;
        opacity: 1;
    }
    &:disabled{
        cursor: not-allowed;
        background: #b4b5b5;
        opacity: .4;
    }
}
@keyframes bigfadeout{
    0%{transform: scale(1);}
    100%{transform: scale(1.2, 1.6); opacity: 0;}
}

.form-group{
    position: relative;
    margin-top: 35px;
    font-size:18px;
    input{
        position: relative;
        display: block;
        padding: 0.5em;
        width: 100%;
        box-sizing: border-box;
        font-size: inherit;
        font-family: inherit;
        font-weight: normal;
        line-height: inherit;
        color: #333;
        background: transparent;
        border: none;
        outline: none;
        border-bottom: #ccc 1px solid;
        transition: all .4s ease;
        &:focus, &:valid{
			border-bottom: #37afac 1px solid;
            &+.form-label{
                color: #37afac;
                font-size: 13px;
                transform: translateY(-25px);
            }

        }
        // &::placeholder {
        //     color: transparent;
        //     opacity: 0;
        // }
        // &:-ms-input-placeholder {
        //     color: transparent;
        // }
        // &::-ms-input-placeholder {
        //     color: transparent;
        // }

    }

}
.checknum .form-group input{
    width: calc(100% - 120px);
}

#rand-img{
    position: absolute;
    bottom: 0;
    right: 0;
}


.form-label{
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    padding: 10px;
    color: #999;
    transition: all .4s ease;
}

.form-group.error{
    border-color: #f57c7c;
}



/*帳號紀錄底色*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
    box-shadow: 0 0 0px 1000px rgba(192,192,192,0.2) inset; /*淡淡的白*/
    transition: background-color 5000s ease-in-out 0s; /*透明*/
    -webkit-text-fill-color: #333; /*字體顏色*/
}


</style>